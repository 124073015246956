
import { defineComponent, PropType, ref, watch } from 'vue'
import { User, createUser, MmTeacher, createMmTeacher } from 'momai'
import { useRoute } from 'vue-router'
import { detail as fetchUserInfo } from '@/api/user'
import { api } from '@/api/useAxios'
import { Toast } from 'vant'
import useVideoUploader from './useVideoUploader'

export default defineComponent({
  props: {
    userdetail: {
      type: Object as PropType<User>,
      required: true
    }
  },

  setup: (props, { emit }) => {
    const userDetail = ref<User>(createUser())
    const { userId } = useRoute().params
    fetchUserInfo(userId).then(r => {
      userDetail.value = r
    })

    const teacherInfo = ref<MmTeacher>(createMmTeacher())
    const fetchTeacher = () => {
      api.get('/mmteacher/getbyuserid/'+userId).then((r) => {
        if(r){
          teacherInfo.value = r
        }
      })
    }
    fetchTeacher()
    return {
      userId,
      userDetail,
      teacherInfo,
      ...useVideoUploader(teacherInfo),
      submit: () => {
        teacherInfo.value.userId = String(userId)
        api.post('/mmteacher/save',teacherInfo.value).then((r) => {
          Toast.success('保存成功！')
        })

      },
    }
  }
})
