import { ref, Ref } from 'vue'
import useUploadVideo from '@/utils/useUploadVideo'
import { MmTeacher } from 'momai'

export default (teacherInfo: Ref<MmTeacher>) => {
  const videoUploader = ref<HTMLInputElement | null>(null)
  const uploadDirectory = 'mmteacher-video'
  return {
    videoUploader,
    teacherVideo: useUploadVideo({
      picker: videoUploader,
      uploadDirectory,
      uploadFinished: (videoUrl, coverUrl, duration) => {
        teacherInfo.value.video = videoUrl.value
        teacherInfo.value.videoCover = coverUrl.value
        teacherInfo.value.videoLength = duration.value
      }
    })

  }
}
